import React, { useState } from "react"
import { Link } from "gatsby"
import styles from './VideoWrap.module.css';
import VisibilitySensor from 'react-visibility-sensor';

const VideoWrap = ({
  video,
  backgroundColor,
}) => {
  const [visible, setVisible] = useState(false);
  const onChange = (isVisible) => {
    if (isVisible) {
      setVisible(true)
    }
  }
  return (
    <VisibilitySensor minTopValue={50} onChange={onChange} partialVisibility={'top'}>
      <div className={styles.wrapper} style={{ opacity: visible ? 1 : 0, backgroundColor }}>
        <video controls style={{ width: '100%' }}>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </VisibilitySensor>
  )
}

export default VideoWrap
