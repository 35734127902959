import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from '../../components/project_page/ProjectPageWrapper';
import TextBlock from '../../components/project_page/TextBlock';
import QuoteBlock from '../../components/project_page/QuoteBlock';
import Mobile3 from '../../components/project_page/Mobile3';
import Web1 from '../../components/project_page/Web1';
import Image1 from '../../components/project_page/Image1';
import ImageGrid from '../../components/project_page/ImageGrid';
import RollCallVid from "../../videos/jjacks.mp4"
import VideoWrap from '../../components/project_page/image_wrappers/VideoWrap';

import globalStyles from '../global.module.css';

const headerBackground = "#e7ffd6"
const navColor="#222";
const Treehouse = ({ data, location }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="Treehouse"
      link={null}
      oneLiner="A new venue for virtual shows"
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="The Collaboration"
          headline={`Designing and building a virtual venue`}
          content={<><p>
            We worked with Treehouse, a company that is building an online venue for
            for virtual shows. We helped develop their brand and launch their initial product -
            a platform for promoting and monetizing interactive video shows.
            </p><p>
            Their platform enables them easily schedule events, sell tickets and communicate
            event details to their customers.
          </p></>}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <Mobile3
          one={<Img fluid={data.body1.childImageSharp.fluid} />}
          two={<Img fluid={data.body2.childImageSharp.fluid} />}
          three={<Img fluid={data.body3.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
        <Web1
          color={"#e7ffd6"}
          desktopImage={<Img fluid={data.body7.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body7.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.flex} ${globalStyles.justifyCenter} ${globalStyles.marginBottomNormal}`}>

        <QuoteBlock title={"Project Feedback"} headline={'The overall outcome of this project was a success in every dimension'} content={'Our team appreciates all the hard work, dedication, and expertise your team has put into this project, and we could not have asked for a better outcome. Working on this project together was a complete pleasure for us. You have far exceeded our expectations in terms of project timeliness, professionalism, work ethic, and most importantly, the development of our MVP. We look forward to pursing many more projects with the Long Weekend Team in the future.'} />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <Web1
          color={"#e7ffd6"}
          desktopImage={<Img fluid={data.body9.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body9.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
          <TextBlock
            title=""
            headline={`Internal Dashboard`}
            content={`An internal dashboard allows the Treehouse team to
              create and manage shows and customers.`
            }
          />
      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
        <Web1
          color={"rgb(220 222 225)"}
          desktopImage={<Img fluid={data.body4.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body4.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomHuge}`}>
        <Web1
          color={"rgb(220 222 225)"}

          desktopImage={<Img fluid={data.body5.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body6.childImageSharp.fluid} />}
        />
      </div>

      <div className={`${globalStyles.marginBottomNormal}`}>
        <div className={`${globalStyles.flex} ${globalStyles.flexCenter} ${globalStyles.mobileFlexColumnReverse}`}>
          <div className={`${globalStyles.flexGrow} ${globalStyles.marginRightBig} ${globalStyles.marginRightDesktopOnly}`}>
            <TextBlock
              title="Engineering"
              headline={`Integrations`}
              content={<><p>We integrated with Stripe Checkout to allow Treehouse to
                sell tickets to their events. We integrated Zoom's API and Webinar functionality to
                enable their team to create events from their custom internal dashboard and
                generate unique event links for their customers.
                </p></>
              }
            />
          </div>
          <div className={`${globalStyles.width100} ${globalStyles.marginBottomMobileOnly}`}>
            <Image1 img={<Img fluid={data.body10.childImageSharp.fluid} />} />
          </div>
        </div>
      </div>
      <div className={`${globalStyles.marginBottomHuge}`}>
        <ImageGrid images={[
          <Img fluid={data.body11.childImageSharp.fluid} />,
          <Img fluid={data.body12.childImageSharp.fluid} />,
          <Img fluid={data.body13.childImageSharp.fluid} />,
        ]} />
      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
          <TextBlock
            title="Design"
            headline={`A Virtual Venue`}
            content={
              <>
              <p>
                While exploring other "online experience" platforms, we found
                that most branded themselves as a "tool" for creators.
                The Treehouse team wanted to go beyond and build
                excitement among people attending the experience.
              </p>
              <p>
                By positioning Treehouse as a "virtual venue" and using nods to physical
                venues we're carrying over the excitement people feel
                when attending a real life show or concert.
              </p>
              </>
            }
          />
      </div>
      <Image1 maxWidth={'600px'} img={<Img fluid={data.body14.childImageSharp.fluid} />} />
    </ProjectPageWrapper>
  </Layout>
)

export default Treehouse

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/treehouse/header.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mobileHeader: file(relativePath: { eq: "projects/treehouse/header-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body1: file(relativePath: { eq: "projects/treehouse/phone1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body2: file(relativePath: { eq: "projects/treehouse/phone2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body3: file(relativePath: { eq: "projects/treehouse/phone3.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body4: file(relativePath: { eq: "projects/treehouse/web1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body5: file(relativePath: { eq: "projects/treehouse/web2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body6: file(relativePath: { eq: "projects/treehouse/web3.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body7: file(relativePath: { eq: "projects/treehouse/site1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body8: file(relativePath: { eq: "projects/treehouse/site2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body9: file(relativePath: { eq: "projects/treehouse/site3.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body10: file(relativePath: { eq: "projects/treehouse/Integrations.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body11: file(relativePath: { eq: "projects/treehouse/person1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body12: file(relativePath: { eq: "projects/treehouse/person2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body13: file(relativePath: { eq: "projects/treehouse/person3.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body14: file(relativePath: { eq: "projects/treehouse/logo.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
